
import { resetAds, resetSpecificAds, destroySpecificAds } from "~/fc/Ads";

export default {
  name: 'AdsSide',
  components: {
    AdsSideLayout: () => import(/* webpackChunkName: "AdsSideLayout" */ './AdsSideLayout')
  },
  data: () => ({
    loadedRight: false,
    loadedLeft: false,
    clientWidth: 0,
    showSplitAds: false
  }),
  computed: {
    freeMember () {
      return !this.$store.state.subscription.loaded || this.$store.state.subscription.name === undefined || this.$store.state.subscription.name === 'Free'
    },
    downloadPage () {
      return this.$route.fullPath.includes('/download')
    },
    leftAdUnitIdentifier () {
      return this.downloadPage 
        ? '/22404391699/FreeConvert_Download_LeftSidebar'
        : '/22404391699/FreeConvert_LeftSidebar'
    },
    rightAdUnitIdentifier () {
      return this.downloadPage 
        ? '/22404391699/FreeConvert_Download_RightSidebar'
        : '/22404391699/FreeConvert_RightSidebar'
    }
  },
  mounted () {
    this.setClientWidth()
    
    /**
     * When the user clicks convert and goes to the download page,
     * we initiate setup for the new Right ads.
     */
    if (this.downloadPage && this.clientWidth >= 1400) this.splitRightAd()

    this.$bus.$on('LOGOUT', () => {
      this.adInPageKey++
      setTimeout(() => { resetAds() }, 200)
    })
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.handleResize()
      });
    })
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    runAdScript () {
      try {
        const fusetag = window.fusetag || (window.fusetag = { que: [] })
        fusetag.registerZone('22845353613')
        fusetag.registerZone('22845353616')
      } catch (e) {
      }
    },
    splitRightAd () {
      /**
       * The Right(300x600) ad is replaced by two 
       * smaller ads(300x250) where The right-top ad will be 
       * <div id="cnx_fc_download_right_top_300x250"></div>. The right-bottom ad 
       * will continue to show the current partner ad. 
       * <div data-aaad='true' data-aa-adunit='/22404391699/FreeConvert_Download_Right_Bottom_300x250'></div>
       * We destroy the RightSidebar(300x600) ad and refresh the Right_Bottom_300x250 ad
       */
      this.showSplitAds = true
      this.switchAdPartner()
    },
    switchAdPartner () {
      resetSpecificAds(['22404391699/FreeConvert_Download_Right_Bottom_300x250'])
      destroySpecificAds(['22404391699/FreeConvert_RightSidebar'])
    },
    handleResize (event) {
      this.setClientWidth()
      this.showSplitAds = this.$route.fullPath.includes('/download') && this.clientWidth >= 1400
      resetAds()
    },
    setClientWidth () {
      this.clientWidth =  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    }
  }
}
